import { ActionCreator } from "redux";
import {
  GET_COST_CENTER_ERROR,
  GET_COST_CENTER_SUCCESS,
  GET_MY_USER_DETAIL_SUCCESS,
  GET_PERSONNEL_SALARY_LIST_ERROR,
  GET_MY_USER_DETAIL_ERROR,
  CostCenter,
  Member,
  memberActions,
  Companies,
  CurrentDebt,
  GET_CURRENT_DEBT_LİST_SUCCESS,
  GET_CURRENT_DEBT_LİST_ERROR,
  GET_COMPANIES_SUCCESS,
  GET_COMPANIES_ERROR,
  GET_MAİN_COST_CENTER_GROUP_SUCCESS,
  GET_MAİN_COST_CENTER_GROUP_ERROR,
  MainCostCenter,
  GET_SUB_COST_CENTER_GROUP_SUCCESS,
  GET_SUB_COST_CENTER_GROUP_ERROR,
  SubCostCenter,
  GET_COST_CENTER_LİST_SUCCESS,
  GET_COST_CENTER_LİST_ERROR,
  CostCenterList,
  GET_COST_CENTER_DETAIL_SUCCESS,
  GET_COST_CENTER_DETAIL_ERROR,
  CostCenterDetail,
  Firm,
  GET_FİRM_NAMES_ERROR,
  GET_FİRM_NAMES_SUCCESS,
  GET_CUSTOMER_LİST_BY_COMPANYID_ERROR,
  GET_CUSTOMER_LİST_BY_COMPANYID_SUCCESS,
  Customer,
  Salary,
  GET_PERSONNEL_SALARY_LIST_SUCCESS,
  AllPersonnel,
  GET_ALL_PERSONNEL_LİST_ERROR,
  GET_ALL_PERSONNEL_LİST_SUCCESS,
  GET_MAİN_COST_CENTER_LİST_ERROR,
  GET_MAİN_COST_CENTER_LİST_SUCCESS,
  GET_SUB_COST_CENTER_LİST_ERROR,
  GET_SUB_COST_CENTER_LİST_SUCCESS,
  GET_COST_CENTER_TREE_LİST_ERROR,
  GET_COST_CENTER_TREE_LİST_SUCCESS,
  MainCostCenterList,
  SubCostCenterList,
  CostCenterTreeList,
  GET_COMPANY_LİST_BY_INPUT_ERROR,
  GET_COMPANY_LİST_BY_INPUT_SUCCESS,
  GET_CUSTOMER_LİST_BY_INPUT_ERROR,
  GET_CUSTOMER_LİST_BY_INPUT_SUCCESS,
  CompanybyInput,
  CustomerbyInput,
  GET_SALARY_DETAİL_LİST_SUCCESS,
  GET_SALARY_DETAİL_LİST_ERROR,
  SalaryDetailList,
  GET_PERSONEL_LİST_BY_SUB_STATUS_SUCCESS,
  GET_PERSONEL_LİST_BY_SUB_STATUS_ERROR,
  PersonelListBySubStatus,
  GET_PERSONEL_LİST_BY_SALARY_DETAİL_ERROR,
  GET_PERSONEL_LİST_BY_SALARY_DETAİL_SUCCESS,
  PersonelListBySalaryDetail,
  GET_CURRENT_DEBT_REPORT_BY_MAİN_CODE_SUCCESS,
  GET_CURRENT_DEBT_REPORT_BY_MAİN_CODE_ERROR,
  GET_CURRENT_DEBT_REPORT_BY_SUB_CODE_SUCCESS,
  GET_CURRENT_DEBT_REPORT_BY_SUB_CODE_ERROR,
  GET_CURRENT_DEBT_REPORT_BY_COST_CENTER_CODE_SUCCESS,
  GET_CURRENT_DEBT_REPORT_BY_COST_CENTER_CODE_ERROR,
  GET_CURRENT_DEBT_REPORT_BY_DETAİL_SUCCESS,
  GET_CURRENT_DEBT_REPORT_BY_DETAİL_ERROR,
  CurrentDebtReportbyMainCode,
  CurrentDebtReportbySubCode,
  CurrentDebtReportbyCostCenterCode,
  CurrentDebtReportbyDetail,
  GET_PERSONNEL_LİST_FOR_LAST_YEAR_SUCCESS,
  GET_PERSONNEL_LİST_FOR_LAST_YEAR_ERROR,
  PersonnelListForLastYear,
  GET_CURRENT_PERİOD_REPORT_BY_COMPANY_SUCCESS,
  GET_CURRENT_PERİOD_REPORT_BY_COMPANY_ERROR,
  CurrentPeriodReportbyCompany,
  GET_CURRENT_PERİOD_REPORT_BY_MONTH_SUCCESS,
  GET_CURRENT_PERİOD_REPORT_BY_MONTH_ERROR,
  CurrentPeriodReportbyMonth,
  GET_CURRENT_PERİOD_REPORT_BY_FİRM_SUCCESS,
  GET_CURRENT_PERİOD_REPORT_BY_FİRM_ERROR,
  CurrentPeriodReportbyFirm,
  GET_EMPLOYEES_BY_INPUT_SUCCESS,
  GET_EMPLOYEES_BY_INPUT_ERROR,
  EmployeesByInput,
  TechnicGroupMainList,
  GET_TECHNİC_GROUP_MAİN_LİST_ERROR,
  GET_TECHNİC_GROUP_MAİN_LİST_SUCCESS,
  TechnicalList,
  GET_ALL_TECHNICAL_GROUP_SUCCESS,
  GET_ALL_TECHNICAL_GROUP_ERROR,
  ProfilabilityByCandidateList,
  GET_PROFILABILITY_BY_CANDIDATE_SUCCESS,
  GET_PROFILABILITY_BY_CANDIDATE_ERROR,
  CandidateList,
  GET_CANDIDATE_LIST_SUCCESS,
  GET_CANDIDATE_LIST_ERROR,
  RecruiterList,
  GET_RECRUITER_LIST_SUCCESS,
  GET_RECRUITER_LIST_ERROR,
  GET_OUTSOURCE_PERSONNEL_LİST_SUCCESS,
  GET_OUTSOURCE_PERSONNEL_LİST_ERROR,
  OutsourcePersonnel,
  GET_COMPANIES_BY_MEMBER_ID_SUCCESS,
  GET_COMPANIES_BY_MEMBER_ID_ERROR,
  CompaniesbyMemberId,
  GET_CUSTOMER_BY_PROFİTABİLİTY_SUCCESS,
  GET_CUSTOMER_BY_PROFİTABİLİTY_ERROR,
  CustomerbyProfitability,
  GET_NETTİNG_LİST_SUCCESS,
  GET_NETTİNG_LİST_ERROR,
  Netting,
  GET_POSİTİON_LİST_SUCCESS,
  GET_POSİTİON_LİST_ERROR,
  PositionList,
  SelectionPlacementByCandidateList,
  GET_SELECTION_PLACEMENT_BY_CANDIDATE_ERROR,
  GET_SELECTION_PLACEMENT_BY_CANDIDATE_SUCCESS,
  GET_SALARY_SUMMARY_LİST_ERROR,
  GET_SALARY_SUMMARY_LİST_SUCCESS,
  SalarySummaryList,
  GET_BUDGET_BY_MAIN_CODE_SUCCESS,
  GET_BUDGET_BY_MAIN_CODE_ERROR,
  GET_BUDGET_BY_SUB_CODE_SUCCESS,
  GET_BUDGET_BY_SUB_CODE_ERROR,
  GET_BUDGET_BY_COST_CENTER_CODE_SUCCESS,
  GET_BUDGET_BY_COST_CENTER_CODE_ERROR,
  GET_BUDGET_GENERAL_REPORT_SUCCESS,
  GET_BUDGET_GENERAL_REPORT_ERROR,
  GET_BUDGET_MAIN_REPORT_SUCCESS,
  GET_BUDGET_MAIN_REPORT_ERROR,
  GET_BUDGET_SUB_REPORT_SUCCESS,
  GET_BUDGET_SUB_REPORT_ERROR,
  GET_BUDGET_DETAIL_REPORT_SUCCESS,
  GET_BUDGET_DETAIL_REPORT_ERROR,
  BudgetByMainCode,
  BudgetBySubCode,
  BudgetByCostCenterCode,
  BudgetGeneralReport,
  BudgetMainReport,
  BudgetSubReport,
  BudgetDetailReport,
  StatusStatisticList,
  ExchangeBankRateList,
  GET_EXCHANGE_BANK_RATES_LİST_SUCCESS,
  GET_EXCHANGE_BANK_RATES_LİST_ERROR,
  GET_STATUS_STATİSTİC_LİST_SUCCESS,
  GET_STATUS_STATİSTİC_LİST_ERROR,
  GET_STATUS_STATİSTİC_BY_MEMBER_LİST_ERROR,
  GET_STATUS_STATİSTİC_BY_MEMBER_LİST_SUCCESS,
  StatusStatisticbyMemberList,
  GET_BUDGET_FORECAST_BY_MAINCODE_LİST_SUCCESS,
  GET_BUDGET_FORECAST_BY_MAINCODE_LİST_ERROR,
  BudgetForeCastbyMainCodeList,
  GET_BUDGET_FORECAST_BY_SUBCODE_LİST_SUCCESS,
  GET_BUDGET_FORECAST_BY_SUBCODE_LİST_ERROR,
  BudgetForeCastbySubCodeList,
  GET_BUDGET_FORECAST_BY_COSTCENTERCODE_LİST_SUCCESS,
  GET_BUDGET_FORECAST_BY_COSTCENTERCODE_LİST_ERROR,
  BudgetForeCastbyCostCenterCodeList

} from "../../redux/actions/actionTypes";
import axios, { AxiosError } from "axios";
import { SERVICEURL } from "../../appconfig";

//Başarılıysa reducer'a GET_TASK_LIST_SUCCESS action'un ve parametresini döndürüyoruz.

const getCostCenterListSuccess: ActionCreator<memberActions> = (
  list: CostCenter[]
) => {
  return { type: GET_COST_CENTER_SUCCESS, payload: list };
};

const getCostCenterListError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_COST_CENTER_ERROR, payload: message };
};

const getCompaniesListSuccess: ActionCreator<memberActions> = (
  list: Companies[]
) => {
  return { type: GET_COMPANIES_SUCCESS, payload: list };
};

const getCompaniesListError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_COMPANIES_ERROR, payload: message };
};

const getCurrentDebtListSuccess: ActionCreator<memberActions> = (
  list: CurrentDebt[]
) => {
  return { type: GET_CURRENT_DEBT_LİST_SUCCESS, payload: list };
};
const getCurrentDebtListError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_CURRENT_DEBT_LİST_ERROR, payload: message };
};
let getMyUserDetailListSuccess: ActionCreator<memberActions> = (
  list: Member[]
) => {
  return { type: GET_MY_USER_DETAIL_SUCCESS, payload: list };
};
const getMyUserDetailListError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_MY_USER_DETAIL_ERROR, payload: message };
};
const getMainCostCenterGroupSuccess: ActionCreator<memberActions> = (
  list: MainCostCenter[]
) => {
  return { type: GET_MAİN_COST_CENTER_GROUP_SUCCESS, payload: list };
};
const getMainCostCenterGroupError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_MAİN_COST_CENTER_GROUP_ERROR, payload: message };
};
const getSubCostCenterGroupSuccess: ActionCreator<memberActions> = (
  list: SubCostCenter[]
) => {
  return { type: GET_SUB_COST_CENTER_GROUP_SUCCESS, payload: list };
};
const getSubCostCenterGroupError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_SUB_COST_CENTER_GROUP_ERROR, payload: message };
};

const getAllCostCenterListSuccess: ActionCreator<memberActions> = (
  list: CostCenterList[]
) => {
  return { type: GET_COST_CENTER_LİST_SUCCESS, payload: list };
};

const getAllCostCenterListError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_COST_CENTER_LİST_ERROR, payload: message };
};
const getCostCenterDetailSuccess: ActionCreator<memberActions> = (
  list: CostCenterDetail[]
) => {
  return { type: GET_COST_CENTER_DETAIL_SUCCESS, payload: list };
};
const getCostCenterDetailError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_COST_CENTER_DETAIL_ERROR, payload: message };
};
const getFirmNamesSuccess: ActionCreator<memberActions> = (list: Firm[]) => {
  return { type: GET_FİRM_NAMES_SUCCESS, payload: list };
};
const getFirmNamesError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_FİRM_NAMES_ERROR, payload: message };
};
const getCustomerListByCompanyIdSuccess: ActionCreator<memberActions> = (
  list: Customer[]
) => {
  return { type: GET_CUSTOMER_LİST_BY_COMPANYID_SUCCESS, payload: list };
};
const getCustomerListByCompanyIdError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_CUSTOMER_LİST_BY_COMPANYID_ERROR, payload: message };
};
const getPersonnelSalaryListSuccess: ActionCreator<memberActions> = (
  list: Salary[]
) => {
  return { type: GET_PERSONNEL_SALARY_LIST_SUCCESS, payload: list };
};
const getPersonnelSalaryListError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_PERSONNEL_SALARY_LIST_ERROR, payload: message };
};
const getAllPersonnelListSuccess: ActionCreator<memberActions> = (
  list: AllPersonnel[]
) => {
  return { type: GET_ALL_PERSONNEL_LİST_SUCCESS, payload: list };
};

const getAllPersonnelListError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_ALL_PERSONNEL_LİST_ERROR, payload: message };
};

const getMainCostCenterListSuccess: ActionCreator<memberActions> = (
  list: MainCostCenterList[]
) => {
  return { type: GET_MAİN_COST_CENTER_LİST_SUCCESS, payload: list };
};

const getMainCostCenterListError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_MAİN_COST_CENTER_LİST_ERROR, payload: message };
};

const getSubCostCenterListSuccess: ActionCreator<memberActions> = (
  list: SubCostCenterList[]
) => {
  return { type: GET_SUB_COST_CENTER_LİST_SUCCESS, payload: list };
};

const getSubCostCenterListError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_SUB_COST_CENTER_LİST_ERROR, payload: message };
};

const getCostCenterTreeListSuccess: ActionCreator<memberActions> = (
  list: CostCenterTreeList[]
) => {
  return { type: GET_COST_CENTER_TREE_LİST_SUCCESS, payload: list };
};

const getCostCenterTreeListError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_COST_CENTER_TREE_LİST_ERROR, payload: message };
};

const getCustomerbyInputListSuccess: ActionCreator<memberActions> = (
  list: CustomerbyInput[]
) => {
  return { type: GET_CUSTOMER_LİST_BY_INPUT_SUCCESS, payload: list };
};

const getCustomerListSuccess: ActionCreator<memberActions> = (
  list: CustomerbyInput[]
) => {
  return { type: GET_CUSTOMER_LİST_BY_INPUT_SUCCESS, payload: list };
};

const getCustomerListError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_CUSTOMER_LİST_BY_INPUT_ERROR, payload: message };
};

const getCustomerbyInputListError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_CUSTOMER_LİST_BY_INPUT_ERROR, payload: message };
};

const getCompanybyInputListSuccess: ActionCreator<memberActions> = (
  list: CompanybyInput[]
) => {
  return { type: GET_COMPANY_LİST_BY_INPUT_SUCCESS, payload: list };
};

const getCompanybyInputListError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_COMPANY_LİST_BY_INPUT_ERROR, payload: message };
};

const getSalarDetailListSuccess: ActionCreator<memberActions> = (
  list: SalaryDetailList[]
) => {
  return { type: GET_SALARY_DETAİL_LİST_SUCCESS, payload: list };
};

const getSalarDetailListError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_SALARY_DETAİL_LİST_ERROR, payload: message };
};

const getPersonelListBySubStatusSuccess: ActionCreator<memberActions> = (
  list: PersonelListBySubStatus[]
) => {
  return { type: GET_PERSONEL_LİST_BY_SUB_STATUS_SUCCESS, payload: list };
};

const getPersonelListBySubStatusError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_PERSONEL_LİST_BY_SUB_STATUS_ERROR, payload: message };
};

const getPersonelListBySalaryDetailSuccess: ActionCreator<memberActions> = (
  list: PersonelListBySalaryDetail[]
) => {
  return { type: GET_PERSONEL_LİST_BY_SALARY_DETAİL_SUCCESS, payload: list };
};

const getPersonelListBySalaryDetailError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_PERSONEL_LİST_BY_SALARY_DETAİL_ERROR, payload: message };
};

const getCurrentDebtReportbyMainCodeSuccess: ActionCreator<memberActions> = (
  list: CurrentDebtReportbyMainCode[]
) => {
  return { type: GET_CURRENT_DEBT_REPORT_BY_MAİN_CODE_SUCCESS, payload: list };
};

const getCurrentDebtReportbyMainCodeError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_CURRENT_DEBT_REPORT_BY_MAİN_CODE_ERROR, payload: message };
};

const getCurrentDebtReportbySubCodeSuccess: ActionCreator<memberActions> = (
  list: CurrentDebtReportbySubCode[]
) => {
  return { type: GET_CURRENT_DEBT_REPORT_BY_SUB_CODE_SUCCESS, payload: list };
};

const getCurrentDebtReportbySubCodeError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_CURRENT_DEBT_REPORT_BY_SUB_CODE_ERROR, payload: message };
};

const getCurrentDebtReportbyCostCenterCodeSuccess: ActionCreator<
  memberActions
> = (list: CurrentDebtReportbyCostCenterCode[]) => {
  return {
    type: GET_CURRENT_DEBT_REPORT_BY_COST_CENTER_CODE_SUCCESS,
    payload: list,
  };
};

const getCurrentDebtReportbyCostCenterCodeError: ActionCreator<
  memberActions
> = (message: string) => {
  return {
    type: GET_CURRENT_DEBT_REPORT_BY_COST_CENTER_CODE_ERROR,
    payload: message,
  };
};

const getCurrentDebtReportbyDetailSuccess: ActionCreator<memberActions> = (
  list: CurrentDebtReportbyDetail[]
) => {
  return { type: GET_CURRENT_DEBT_REPORT_BY_DETAİL_SUCCESS, payload: list };
};

const getCurrentDebtReportbyDetailError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_CURRENT_DEBT_REPORT_BY_DETAİL_ERROR, payload: message };
};

const getPersonelListForLastYearSuccess: ActionCreator<memberActions> = (
  list: PersonnelListForLastYear[]
) => {
  return { type: GET_PERSONNEL_LİST_FOR_LAST_YEAR_SUCCESS, payload: list };
};

const getPersonelListForLastYearError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_PERSONNEL_LİST_FOR_LAST_YEAR_ERROR, payload: message };
};
const getCurrentPeriodReportByCompanySuccess: ActionCreator<memberActions> = (
  list: CurrentPeriodReportbyCompany[]
) => {
  return { type: GET_CURRENT_PERİOD_REPORT_BY_COMPANY_SUCCESS, payload: list };
};

const getCurrentPeriodReportByCompanyError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_CURRENT_PERİOD_REPORT_BY_COMPANY_ERROR, payload: message };
};
const getCurrentPeriodReportByMonthSuccess: ActionCreator<memberActions> = (
  list: CurrentPeriodReportbyMonth[]
) => {
  return { type: GET_CURRENT_PERİOD_REPORT_BY_MONTH_SUCCESS, payload: list };
};

const getCurrentPeriodReportByMonthError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_CURRENT_PERİOD_REPORT_BY_MONTH_ERROR, payload: message };
};
const getCurrentPeriodReportByFirmSuccess: ActionCreator<memberActions> = (
  list: CurrentPeriodReportbyFirm[]
) => {
  return { type: GET_CURRENT_PERİOD_REPORT_BY_FİRM_SUCCESS, payload: list };
};

const getCurrentPeriodReportByFirmError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_CURRENT_PERİOD_REPORT_BY_FİRM_ERROR, payload: message };
};
const getEmployeesByInputSuccess: ActionCreator<memberActions> = (
  list: EmployeesByInput[]
) => {
  return { type: GET_EMPLOYEES_BY_INPUT_SUCCESS, payload: list };
};
const getEmployeesByInputError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_EMPLOYEES_BY_INPUT_ERROR, payload: message };
};
const getTechnicGroupMainListSuccess: ActionCreator<memberActions> = (
  list: TechnicGroupMainList[]
) => {
  return { type: GET_TECHNİC_GROUP_MAİN_LİST_SUCCESS, payload: list };
};

const getTechnicGroupMainListError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_TECHNİC_GROUP_MAİN_LİST_ERROR, payload: message };
};
const getAllTechnicalGroupSuccess: ActionCreator<memberActions> = (
  list: TechnicalList[]
) => {
  return { type: GET_ALL_TECHNICAL_GROUP_SUCCESS, payload: list };
};

const getAllTechnicalGroupError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_ALL_TECHNICAL_GROUP_ERROR, payload: message };
};
const getProfilabilityByCandidateListSuccess: ActionCreator<memberActions> = (
  list: ProfilabilityByCandidateList[]
) => {
  return { type: GET_PROFILABILITY_BY_CANDIDATE_SUCCESS, payload: list };
};

const getProfilabilityByCandidateListError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_PROFILABILITY_BY_CANDIDATE_ERROR, payload: message };
};
const getSelectonPlacementByCandidateListSuccess: ActionCreator<
  memberActions
> = (list: SelectionPlacementByCandidateList[]) => {
  return { type: GET_SELECTION_PLACEMENT_BY_CANDIDATE_SUCCESS, payload: list };
};

const getSelectionPlacementByCandidateListError: ActionCreator<
  memberActions
> = (message: string) => {
  return { type: GET_SELECTION_PLACEMENT_BY_CANDIDATE_ERROR, payload: message };
};
const getCandidateListSuccess: ActionCreator<memberActions> = (
  list: CandidateList[]
) => {
  return { type: GET_CANDIDATE_LIST_SUCCESS, payload: list };
};

const getCandidateListError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_CANDIDATE_LIST_ERROR, payload: message };
};

const getRecruiterListSuccess: ActionCreator<memberActions> = (
  list: RecruiterList[]
) => {
  return { type: GET_RECRUITER_LIST_SUCCESS, payload: list };
};

const getRecruiterListError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_RECRUITER_LIST_ERROR, payload: message };
};

const getOutsourcePersonnelListSuccess: ActionCreator<memberActions> = (
  list: OutsourcePersonnel[]
) => {
  return { type: GET_OUTSOURCE_PERSONNEL_LİST_SUCCESS, payload: list };
};

const getOutsourcePersonnelListError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_OUTSOURCE_PERSONNEL_LİST_ERROR, payload: message };
};

const getCompaniesByMemberIdListSuccess: ActionCreator<memberActions> = (
  list: CompaniesbyMemberId[]
) => {
  return { type: GET_COMPANIES_BY_MEMBER_ID_SUCCESS, payload: list };
};

const getCompaniesByMemberIdListError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_COMPANIES_BY_MEMBER_ID_ERROR, payload: message };
};

const getCustomerByProfitabilitySuccess: ActionCreator<memberActions> = (
  list: CustomerbyProfitability[]
) => {
  return { type: GET_CUSTOMER_BY_PROFİTABİLİTY_SUCCESS, payload: list };
};

const getCustomerByProfitabilityError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_CUSTOMER_BY_PROFİTABİLİTY_ERROR, payload: message };
};

const getNettingListSuccess: ActionCreator<memberActions> = (
  list: Netting[]
) => {
  return { type: GET_NETTİNG_LİST_SUCCESS, payload: list };
};

const getNettingListError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_NETTİNG_LİST_ERROR, payload: message };
};

const getPositionListSuccess: ActionCreator<memberActions> = (
  list: PositionList[]
) => {
  return { type: GET_POSİTİON_LİST_SUCCESS, payload: list };
};

const getPositionListError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_POSİTİON_LİST_ERROR, payload: message };
};

const getSalarySummarySuccess: ActionCreator<memberActions> = (
  list: SalarySummaryList[]
) => {
  return { type: GET_SALARY_SUMMARY_LİST_SUCCESS, payload: list };
};

const getSalarySummaryError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_SALARY_SUMMARY_LİST_ERROR, payload: message };
};

const getBudgetByMainCodeSuccess: ActionCreator<memberActions> = (
  list: BudgetByMainCode[]
) => {
  return { type: GET_BUDGET_BY_MAIN_CODE_SUCCESS, payload: list };
};

const getBudgetByMainCodeError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_BUDGET_BY_MAIN_CODE_ERROR, payload: message };
};

const getBudgetBySubCodeSuccess: ActionCreator<memberActions> = (
  list: BudgetBySubCode[]
) => {
  return { type: GET_BUDGET_BY_SUB_CODE_SUCCESS, payload: list };
};

const getBudgetBySubCodeError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_BUDGET_BY_SUB_CODE_ERROR, payload: message };
};

const getBudgetByCostCenterCodeSuccess: ActionCreator<memberActions> = (
  list: BudgetByCostCenterCode[]
) => {
  return { type: GET_BUDGET_BY_COST_CENTER_CODE_SUCCESS, payload: list };
};

const getBudgetByCostCenterCodeError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_BUDGET_BY_COST_CENTER_CODE_ERROR, payload: message };
};

const getBudgetGeneralReportSuccess: ActionCreator<memberActions> = (
  list: BudgetGeneralReport[]
) => {
  return { type: GET_BUDGET_GENERAL_REPORT_SUCCESS, payload: list };
};

const getBudgetGeneralReportError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_BUDGET_GENERAL_REPORT_ERROR, payload: message };
};

const getBudgetMainReportSuccess: ActionCreator<memberActions> = (
  list: BudgetMainReport[]
) => {
  return { type: GET_BUDGET_MAIN_REPORT_SUCCESS, payload: list };
};

const getBudgetMainReportError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_BUDGET_MAIN_REPORT_ERROR, payload: message };
};

const getBudgetSubReportSuccess: ActionCreator<memberActions> = (
  list: BudgetSubReport[]
) => {
  return { type: GET_BUDGET_SUB_REPORT_SUCCESS, payload: list };
};

const getBudgetSubReportError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_BUDGET_SUB_REPORT_ERROR, payload: message };
};

const getBudgetDetailReportSuccess: ActionCreator<memberActions> = (
  list: BudgetDetailReport[]
) => {
  return { type: GET_BUDGET_DETAIL_REPORT_SUCCESS, payload: list };
};

const getBudgetDetailReportError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_BUDGET_DETAIL_REPORT_ERROR, payload: message };
};

const getStatusStatisticListSuccess: ActionCreator<memberActions> = (
  list: StatusStatisticList[]
) => {
  return { type: GET_STATUS_STATİSTİC_LİST_SUCCESS, payload: list };
};

const getStatusStatisticListError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_STATUS_STATİSTİC_LİST_ERROR, payload: message };
};

const getExchangeBankRatesListSuccess: ActionCreator<memberActions> = (
  list: ExchangeBankRateList[]
) => {
  return { type: GET_EXCHANGE_BANK_RATES_LİST_SUCCESS, payload: list };
};

const getExchangeBankRatesListError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_EXCHANGE_BANK_RATES_LİST_ERROR, payload: message };
};


const getStatusStatisticbyMemberListSuccess: ActionCreator<memberActions> = (
  list: StatusStatisticbyMemberList[]
) => {
  return { type: GET_STATUS_STATİSTİC_BY_MEMBER_LİST_SUCCESS, payload: list };
};

const getStatusStatisticbyMemberListError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_STATUS_STATİSTİC_BY_MEMBER_LİST_ERROR, payload: message };
};

const getBudgetForecastListByMainCodeListSuccess: ActionCreator<memberActions> = (
  list: BudgetForeCastbyMainCodeList[]
) => {
  return { type: GET_BUDGET_FORECAST_BY_MAINCODE_LİST_SUCCESS, payload: list };
};

const getBudgetForecastListByMainCodeListError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_BUDGET_FORECAST_BY_MAINCODE_LİST_ERROR, payload: message };
};

const getBudgetForecastListBySubCodeListSuccess: ActionCreator<memberActions> = (
  list: BudgetForeCastbySubCodeList[]
) => {
  return { type: GET_BUDGET_FORECAST_BY_SUBCODE_LİST_SUCCESS, payload: list };
};

const getBudgetForecastListBySubCodeListError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_BUDGET_FORECAST_BY_SUBCODE_LİST_ERROR, payload: message };
};

const getBudgetForecastListByCostCenterCodeListSuccess: ActionCreator<memberActions> = (
  list: BudgetForeCastbyCostCenterCodeList[]
) => {
  return { type: GET_BUDGET_FORECAST_BY_COSTCENTERCODE_LİST_SUCCESS, payload: list };
};

const getBudgetForecastListByCostCenterCodeListError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_BUDGET_FORECAST_BY_COSTCENTERCODE_LİST_ERROR, payload: message };
};

export function getStatusStatisticbyMemberList(state: {
  state: {
    dateStart: Date | null;
    dateEnd: Date | null;
    statusName: string;
    ikPersonnelId: number;
  };
}) {
  const dateStart = state.state.dateStart;
  const dateEnd = state.state.dateEnd;
  const ikPersonnelId = state.state.ikPersonnelId;
  const statusName = state.state.statusName;

  return (dispatch: (arg0: memberActions) => void) => {
    if (
      dateStart != null &&
      dateStart.getFullYear() != 1970 &&
      dateEnd != null &&
      dateEnd.getFullYear() != 1970
    ) {
      const input = {
        StartDate: dateStart,
        EndDate: dateEnd,
        StatusName: statusName,
        IkPersonnelId: ikPersonnelId,
      };
      axios
        .post(
          SERVICEURL + "api/StatusStaticReport/GetStatusStaticByMember?",
          input,
          HEADER
        )
        .then((response) => {
          dispatch(getStatusStatisticbyMemberListSuccess(response));
        })
        .catch((error) => {
          dispatch(getStatusStatisticbyMemberListError("Save error."));
        });
    }
  };
}

export function getStatusStatisticList(state: {
  state: {
    dateStart: Date | null;
    dateEnd: Date | null;
    statusName: string;
    ikPersonnelId: number;
  };
}) {
  const dateStart = state.state.dateStart;
  const dateEnd = state.state.dateEnd;
  const ikPersonnelId = state.state.ikPersonnelId;
  const statusName = state.state.statusName;

  return (dispatch: (arg0: memberActions) => void) => {
    if (
      dateStart != null &&
      dateStart.getFullYear() != 1970 &&
      dateEnd != null &&
      dateEnd.getFullYear() != 1970
    ) {
      const input = {
        StartDate: dateStart,
        EndDate: dateEnd,
        StatusName: statusName,
        IkPersonnelId: ikPersonnelId,
      };
      axios
        .post(
          SERVICEURL + "api/StatusStaticReport/ListStatusStatic?",
          input,
          HEADER
        )
        .then((response) => {
          dispatch(getStatusStatisticListSuccess(response));
        })
        .catch((error) => {
          dispatch(getStatusStatisticListError("Save error."));
        });
    }
  };
}

export function getExchangeBankRatesList(state: {
  state: {
    dateStart: Date | null;
    dateEnd: Date | null;
  };
}) {
  const dateStart = state.state.dateStart;
  const dateEnd = state.state.dateEnd;

  return (dispatch: (arg0: memberActions) => void) => {
    if (
      dateStart != null &&
      dateStart.getFullYear() != 1970 &&
      dateEnd != null &&
      dateEnd.getFullYear() != 1970
    ) {
      const input = {
        StartDate: dateStart,
        EndDate: dateEnd,
      };
      axios
        .post(
          SERVICEURL + "api/Finance/GetExchangeRates",
          input,
          HEADER
        )
        .then((response) => {
          dispatch(getExchangeBankRatesListSuccess(response));
        })
        .catch((error) => {
          dispatch(getExchangeBankRatesListError("Save error."));
        });
    }
  };
}

export function getCostCenterList(state: { state: { name: string } }) {
  const name = state.state.name;
  return (dispatch: (arg0: memberActions) => void) => {
    const mytoken = localStorage.getItem("token");
    const HEADER = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + mytoken,
      },
    };
    if (name != null) {
      const input = { memberId: name };
      axios
        .get(SERVICEURL + "api/Data/GetActiveCostCenter?", HEADER)
        .then((response) => {
          dispatch(getCostCenterListSuccess(response));
        })
        .catch((reason: AxiosError) => {
          if (reason.response!.status === 401) {
            localStorage.removeItem("token");
          } else {
            console.log("Handle else: ", reason.message);
          }
        });
    }
  };
}

const mytoken = localStorage.getItem("token");
const HEADER = {
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + mytoken,
  },
};

export function getCompaniesList() {
  return (dispatch: (arg0: memberActions) => void) => {
    axios
      .get(SERVICEURL + "api/Data/GetCompanies?", HEADER)
      .then((response) => {
        dispatch(getCompaniesListSuccess(response));
      })
      .catch((reason: AxiosError) => {
        if (reason.response && reason.response.status === 401) {
          localStorage.removeItem("token");
          window.location.href = window.location.href;
        } else {
          console.log("Handle else: ", reason.message);
        }
      });
  };
}
export function getMyUserDetailList(state: { state: { id: number } }) {
  const id = state.state.id;
  return (dispatch: (arg0: memberActions) => void) => {
    if (id != null) {
      const input = { memberId: id };
      axios
        .post(SERVICEURL + "api/Membership/GetMyDetail?", input, HEADER)
        .then((response) => {
          dispatch(getMyUserDetailListSuccess(response));
        })
        .catch((reason: AxiosError) => {
          if (reason.response!.status === 401) {
            localStorage.removeItem("token");
          } else {
            console.log("Handle else: ", reason.message);
          }
        });
    }
  };
}
export function getCurrentDebtList(state: {
  state: {
    firmName: string;
    costCenterCode: number;
    companies: number;
    debtDate: Date;
    debtEndDate: Date;
    costCenter: string[];
    isBudgetCheck: boolean | null;
  };
}) {
  const firmName = state.state.firmName;
  //const currentDebtYear = state.state.currentDebtYear.toString();
  const costCenterCode = state.state.costCenterCode;
  const companies = state.state.companies;
  const debtDate = state.state.debtDate;
  const debtEndDate = state.state.debtEndDate;
  const costCenter = state.state.costCenter;
  const IsBudget = state.state.isBudgetCheck;

  return (dispatch: (arg0: memberActions) => void) => {
    if (
      firmName != null ||
      // costCenterCode != null ||
      companies != null ||
      debtDate != null ||
      debtEndDate != null ||
      costCenter != null
    ) {
      const input = {
        CompanyId: companies,
        // CostCenterParameterId: costCenterCode,
        FirmName: firmName,
        DebtDate: debtDate,
        DebtEndDate: debtEndDate,
        CostCenterParameterCode: costCenter,
        IsBudget: IsBudget,
      };
      axios
        .post(SERVICEURL + "api/Finance/ListCurrentDebt", input, HEADER)
        .then((response) => {
          dispatch(getCurrentDebtListSuccess(response));
        })
        .catch((error) => {
          dispatch(getCurrentDebtListError("Save error."));
        });
    }
  };
}

export function getMainCostCenterGroupList(state: { state: { name: string } }) {
  const name = state.state.name;
  return (dispatch: (arg0: memberActions) => void) => {
    if (name != null) {
      const input = { name: name };
      axios
        .get(SERVICEURL + "api/Data/GetMainCostCenterGroup?", HEADER)
        .then((response) => {
          dispatch(getMainCostCenterGroupSuccess(response));
        })
        .catch((reason: AxiosError) => {
          if (reason.response!.status === 401) {
            localStorage.removeItem("token");
          } else {
            console.log("Handle else: ", reason.message);
          }
        });
    }
  };
}

export function getSubCostCenterGroupList(state: { state: { name: string } }) {
  const name = state.state.name;
  return (dispatch: (arg0: memberActions) => void) => {
    if (name != null) {
      const input = { name: name };
      axios
        .get(SERVICEURL + "api/Data/GetSubCostCenterGroup?", HEADER)
        .then((response) => {
          dispatch(getSubCostCenterGroupSuccess(response));
        })
        .catch((reason: AxiosError) => {
          if (reason.response!.status === 401) {
            localStorage.removeItem("token");
          } else {
            console.log("Handle else: ", reason.message);
          }
        });
    }
  };
}

export function getAllCostCenterList(state: {
  isConstant: boolean | null;
  costTypeSelectNumber: number | null;
  state: {
    companies: number;
    mainGroupName: string;
    subGroupName: string;
    costCenterDetail: string;
  };
}) {
  const mainGroupName = state.state.mainGroupName;
  const companies = state.state.companies;
  const subGroupName = state.state.subGroupName;
  const costCenterDetail = state.state.costCenterDetail;
  const isConstant = state.isConstant;
  const costType = state.costTypeSelectNumber;


  return (dispatch: (arg0: memberActions) => void) => {
    if (
      mainGroupName != null ||
      companies != null ||
      subGroupName != null ||
      costCenterDetail != null
    ) {
      const input = {
        CompanyId: companies,
        MainGroupName: mainGroupName,
        SubGroupName: subGroupName,
        CostCenterDetail: costCenterDetail,
        IsConstant: null,
        CostType: costType
      };
      axios
        .post(
          SERVICEURL + "api/Data/GetActiveCostCenterbyInput?",
          input,
          HEADER
        )
        .then((response) => {
          dispatch(getAllCostCenterListSuccess(response));
        })
        .catch((error) => {
          dispatch(getAllCostCenterListError("Save error."));
        });
    }
  };
}

export function getCostCenterDetailList(state: { state: { name: string } }) {
  const name = state.state.name;
  return (dispatch: (arg0: memberActions) => void) => {
    if (name != null) {
      const input = { name: name };
      axios
        .get(SERVICEURL + "api/Data/GetCostCenterDetail?", HEADER)
        .then((response) => {
          dispatch(getCostCenterDetailSuccess(response));
        })
        .catch((reason: AxiosError) => {
          if (reason.response!.status === 401) {
            localStorage.removeItem("token");
          } else {
            console.log("Handle else: ", reason.message);
          }
        });
    }
  };
}

export function getFirmNamesList(state: { state: { firmName: string } }) {
  const name = state.state.firmName;
  return (dispatch: (arg0: memberActions) => void) => {
    if (name != null) {
      const input = { name: name };
      axios
        .get(SERVICEURL + "api/Data/GetFirmNames", HEADER)
        .then((response) => {
          dispatch(getFirmNamesSuccess(response));
        })
        .catch((reason: AxiosError) => {
          if (reason.response!.status === 401) {
            localStorage.removeItem("token");
          } else {
            console.log("Handle else: ", reason.message);
          }
        });
    }
  };
}

export function getCustomerListByCompanyId(state: {
  state: { companyId: number };
}) {
  const id = state.state.companyId;
  return (dispatch: (arg0: memberActions) => void) => {
    if (id != null) {
      const input = { Id: id };
      axios
        .post(SERVICEURL + "api/Data/GetCustomerListbyCompanyId", input, HEADER)
        .then((response) => {
          dispatch(getCustomerListByCompanyIdSuccess(response));
        })
        .catch((error: AxiosError) => {
          dispatch(getCustomerListByCompanyIdError("Save error."));
          if (error.response!.status === 401) {
            localStorage.removeItem("token");
          }
        });
    }
  };
}

export function getPersonnelSalaryList(state: {
  state: {
    companyId: number;
    customerId: number;
    memberId: number;
    tc: string;
    isTecno: string;
  };
}) {
  const companyId = state.state.companyId;
  const customerId = state.state.customerId;
  const memberId = state.state.memberId;
  const tcNo = state.state.tc;
  const isTecno = state.state.isTecno;

  return (dispatch: (arg0: memberActions) => void) => {
    if (
      companyId != null ||
      customerId != null ||
      memberId != null ||
      tcNo != null ||
      isTecno != null
    ) {
      const input = {
        CompanyId: companyId,
        CustomerId: customerId,
        MemberId: memberId,
        TcNo: tcNo,
        IsTecno: isTecno,
      };
      axios
        .post(SERVICEURL + "api/Finance/ListPersonelSalary", input, HEADER)
        .then((response) => {
          dispatch(getPersonnelSalaryListSuccess(response));
        })
        .catch((error) => {
          dispatch(getPersonnelSalaryListError("Save error."));
        });
    }
  };
}

export function getPersonnelAllList() {
  return (dispatch: (arg0: memberActions) => void) => {
    axios
      .get(SERVICEURL + "api/Data/GetAllPersonnelList", HEADER)
      .then((response) => {
        dispatch(getAllPersonnelListSuccess(response));
      })
      .catch((error) => {
        dispatch(getAllPersonnelListError("Save error."));
      });
  };
}

export function getMainCostCenterList() {
  return (dispatch: (arg0: memberActions) => void) => {
    axios
      .get(SERVICEURL + "api/Data/GetMainCostCenterList", HEADER)
      .then((response) => {
        dispatch(getMainCostCenterListSuccess(response));
      })
      .catch((error) => {
        dispatch(getMainCostCenterListError("Save error."));
      });
  };
}

export function getSubCostCenterList() {
  return (dispatch: (arg0: memberActions) => void) => {
    axios
      .get(SERVICEURL + "api/Data/GetSubCostCenterList", HEADER)
      .then((response) => {
        dispatch(getSubCostCenterListSuccess(response));
      })
      .catch((error) => {
        dispatch(getSubCostCenterListError("Save error."));
      });
  };
}

export function getCostCenterTreeList() {
  return (dispatch: (arg0: memberActions) => void) => {
    axios
      .get(SERVICEURL + "api/Data/GetCostCenterTreeList", HEADER)
      .then((response) => {
        dispatch(getCostCenterTreeListSuccess(response));
      })
      .catch((error) => {
        dispatch(getCostCenterTreeListError("Save error."));
      });
  };
}

export function getCustomerbyInputList(state: {
  state: { companyId: number; memberId: number };
}) {
  const companyId = state.state.companyId;
  const memberId = state.state.memberId;

  return (dispatch: (arg0: memberActions) => void) => {
    if (companyId != null || memberId != null) {
      const input = { MemberID: memberId, CompanyID: companyId };
      axios
        .post(SERVICEURL + "api/Data/GetCustomerListByInput", input, HEADER)
        .then((response) => {
          dispatch(getCustomerbyInputListSuccess(response));
        })
        .catch((error: AxiosError) => {
          dispatch(getCustomerbyInputListError("Save error."));
          if (error.response!.status === 401) {
            localStorage.removeItem("token");
          }
        });
    }
  };
}

export function getCustomerList() {
  return (dispatch: (arg0: memberActions) => void) => {
    axios
      .post(SERVICEURL + "api/Data/GetCustomerList", HEADER)
      .then((response) => {
        dispatch(getCustomerListSuccess(response));
      })
      .catch((error: AxiosError) => {
        dispatch(getCustomerListError("Save error."));
        if (error.response!.status === 401) {
          localStorage.removeItem("token");
        }
      });
  };
}

export function getCompanybyInputList(state: {
  state: { customerId: number; memberId: number };
}) {
  const customerId = state.state.customerId;
  const memberId = state.state.memberId;

  return (dispatch: (arg0: memberActions) => void) => {
    if (customerId != null || memberId != null) {
      const input = { MemberID: memberId, CustomerID: customerId };
      axios
        .post(SERVICEURL + "api/Data/GetCompanyListByInput", input, HEADER)
        .then((response) => {
          dispatch(getCompanybyInputListSuccess(response));
        })
        .catch((error: AxiosError) => {
          dispatch(getCompanybyInputListError("Save error."));
          if (error.response!.status === 401) {
            localStorage.removeItem("token");
          }
        });
    }
  };
}

export function getSalaryDetailList(state: {
  state: {
    customerId: number;
    nameId: number;
    companyId: number;
    sicilNo: number;
    startDate: Date | null;
    endDate: Date | null;
  };
  workingStatus: { active: boolean; passive: boolean };
}) {
  const customerId = state.state.customerId;
  const memberId = state.state.nameId;
  const companyId = state.state.companyId;
  const sicilNo = state.state.sicilNo;
  const salaryStartDate = state.state.startDate;
  const salaryEndDate = state.state.endDate;
  const active = state.workingStatus.active;
  const passive = state.workingStatus.passive;

  return (dispatch: (arg0: memberActions) => void) => {
    if (
      customerId != null ||
      memberId != null ||
      companyId != null ||
      sicilNo != null ||
      salaryStartDate != null ||
      salaryEndDate != null
    ) {
      const input = {
        MemberId: memberId,
        CustomerId: customerId,
        CompanyId: companyId,
        SicilNo: sicilNo,
        IsActive: active,
        IsPassive: passive,
        StartDate: salaryStartDate,
        EndDate: salaryEndDate,
      };
      axios
        .post(SERVICEURL + "api/Finance/ListSalaryDetail", input, HEADER)
        .then((response) => {
          dispatch(getSalarDetailListSuccess(response));
        })
        .catch((error: AxiosError) => {
          dispatch(getSalarDetailListError("Save error."));
          if (error.response!.status === 401) {
            //localStorage.removeItem("token");
            dispatch(getSalarDetailListError("no authorization"));
          }
        });
    }
  };
}

export function getPersonelListBySubStatus() {
  return (dispatch: (arg0: memberActions) => void) => {
    axios
      .get(SERVICEURL + "api/Data/GetPersonnelListbySubStatus", HEADER)
      .then((response) => {
        dispatch(getPersonelListBySubStatusSuccess(response));
      })
      .catch((error) => {
        dispatch(getPersonelListBySubStatusError("Save error."));
      });
  };
}

export function getPersonelListBySalaryDetail(state: {
  state: { customerId: number; companyId: number; period: Date | null };
}) {
  const customerId = state.state.customerId;
  const companyId = state.state.companyId;
  const period = state.state.period;

  return (dispatch: (arg0: memberActions) => void) => {
    if (customerId != null || companyId != null) {
      const input = {
        CompanyID: companyId,
        CustomerID: customerId,
        Period: period,
      };
      axios
        .post(
          SERVICEURL + "api/Data/GetPersonelListBySalaryDetail",
          input,
          HEADER
        )
        .then((response) => {
          dispatch(getPersonelListBySalaryDetailSuccess(response));
        })
        .catch((error: AxiosError) => {
          dispatch(getPersonelListBySalaryDetailError("Save error."));
          if (error.response!.status === 401) {
            localStorage.removeItem("token");
          }
        });
    }
  };
}

export function getCurrentDebtReportbyMainCode(state: {
  companyIds: number[];
  OperationalStability: { isOperationalStability: boolean };
  state: { debtDate: Date | null; companyId: number };
}) {
  const debtDate = state.state.debtDate;
  const companyId = state.state.companyId;
  const companyIds = state.companyIds;


  const isOperationalStability =
    state.OperationalStability.isOperationalStability;

  return (dispatch: (arg0: memberActions) => void) => {
    if (debtDate != null || companyId != null) {
      const input = {
        CompanyIds: companyIds,
        DebtDate: debtDate,
        IsOperationalStability: isOperationalStability,
      };
      axios
        .post(
          SERVICEURL + "api/Finance/ListCurrentDebtReportbyMainCode",
          input,
          HEADER
        )
        .then((response) => {
          dispatch(getCurrentDebtReportbyMainCodeSuccess(response));
        })
        .catch((error: AxiosError) => {
          dispatch(getCurrentDebtReportbyMainCodeError("Save error."));
          if (error.response!.status === 401) {
            localStorage.removeItem("token");
          }
        });
    }
  };
}

export function getCurrentDebtReportbySubCode(state: {
  OperationalStability: { isOperationalStability: boolean };
  companyIds: number[];
  state: { debtDate: Date | null; companyId: number; mainGroupCode: string };
}) {
  const debtDate = state.state.debtDate;
  const companyId = state.state.companyId;
  const companyIds = state.companyIds;
  const mainGroupCode = state.state.mainGroupCode;
  const isOperationalStability =
    state.OperationalStability.isOperationalStability;

  return (dispatch: (arg0: memberActions) => void) => {
    if (debtDate != null || companyId != null) {
      const input = {
        CompanyIds: companyIds,
        DebtDate: debtDate,
        CostCenterMainCode: mainGroupCode,
        IsOperationalStability: isOperationalStability,
      };
      axios
        .post(
          SERVICEURL + "api/Finance/ListCurrentDebtReportbySubCode",
          input,
          HEADER
        )
        .then((response) => {
          dispatch(getCurrentDebtReportbySubCodeSuccess(response));
        })
        .catch((error: AxiosError) => {
          dispatch(getCurrentDebtReportbySubCodeError("Save error."));
          if (error.response!.status === 401) {
            localStorage.removeItem("token");
          }
        });
    }
  };
}

export function getCurrentDebtReportbyCostCenterCode(state: {
  OperationalStability: { isOperationalStability: boolean };
  companyIds: number[];
  state: { debtDate: Date | null; companyId: number; subGroupCode: string };
}) {
  const debtDate = state.state.debtDate;
  const companyId = state.state.companyId;
  const companyIds = state.companyIds;
  const subGroupCode = state.state.subGroupCode;
  const isOperationalStability =
    state.OperationalStability.isOperationalStability;

  return (dispatch: (arg0: memberActions) => void) => {
    if (debtDate != null || companyId != null) {
      const input = {
        CompanyIds: companyIds,
        DebtDate: debtDate,
        CostCenterSubCode: subGroupCode,
        IsOperationalStability: isOperationalStability,
      };
      axios
        .post(
          SERVICEURL + "api/Finance/ListCurrentDebtReportbyCostCenterCode",
          input,
          HEADER
        )
        .then((response) => {
          dispatch(getCurrentDebtReportbyCostCenterCodeSuccess(response));
        })
        .catch((error: AxiosError) => {
          dispatch(getCurrentDebtReportbyCostCenterCodeError("Save error."));
          if (error.response!.status === 401) {
            localStorage.removeItem("token");
          }
        });
    }
  };
}

export function getCurrentDebtReportbyDetail(state: {
  OperationalStability: { isOperationalStability: boolean };
  companyIds: number[];
  state: { debtDate: Date | null; companyId: number; costCenterCode: string };
}) {
  const debtDate = state.state.debtDate;
  const companyId = state.state.companyId;
  const companyIds = state.companyIds;
  const costCenterCode = state.state.costCenterCode;
  const isOperationalStability =
    state.OperationalStability.isOperationalStability;

  return (dispatch: (arg0: memberActions) => void) => {
    if (debtDate != null || companyId != null) {
      const input = {
        CompanyIds: companyIds,
        DebtDate: debtDate,
        CostCenterCode: costCenterCode,
        IsOperationalStability: isOperationalStability,
      };
      axios
        .post(
          SERVICEURL + "api/Finance/ListCurrentDebtReportbyDetail",
          input,
          HEADER
        )
        .then((response) => {
          dispatch(getCurrentDebtReportbyDetailSuccess(response));
        })
        .catch((error: AxiosError) => {
          dispatch(getCurrentDebtReportbyDetailError("Save error."));
          if (error.response!.status === 401) {
            localStorage.removeItem("token");
          }
        });
    }
  };
}

export function getPersonelListForLastYear() {
  return (dispatch: (arg0: memberActions) => void) => {
    axios
      .get(SERVICEURL + "api/Data/GetPersonelListForLastYear", HEADER)
      .then((response) => {
        dispatch(getPersonelListForLastYearSuccess(response));
      })
      .catch((error) => {
        dispatch(getPersonelListForLastYearError("Save error."));
      });
  };
}

export function getCurrentPeriodReportbyCompany(state: {
  companyIds: number[];
  state: { debtDate: Date | null; companyId: number };
}) {
  const debtDate = state.state.debtDate;
  const companyId = state.state.companyId;
  const companyIds = state.companyIds;

  return (dispatch: (arg0: memberActions) => void) => {
    if (debtDate != null || companyId != null) {
      const input = { CompanyIds: companyIds, DebtDate: debtDate };
      axios
        .post(
          SERVICEURL + "api/Finance/ListCurrentPeriodReportbyCompany",
          input,
          HEADER
        )
        .then((response) => {
          dispatch(getCurrentPeriodReportByCompanySuccess(response));
        })
        .catch((error: AxiosError) => {
          dispatch(getCurrentPeriodReportByCompanyError("Save error."));
          if (error.response!.status === 401) {
            localStorage.removeItem("token");
          }
        });
    }
  };
}

export function getCurrentPeriodReportbyMonth(state: {
  companyIds: number[];
  state: { debtDatebyMonth: Date | null; companyId: number };
}) {
  const debtDate = state.state.debtDatebyMonth;
  const companyId = state.state.companyId;
  const companyIds = state.companyIds;

  return (dispatch: (arg0: memberActions) => void) => {
    if (debtDate != null || companyId != null) {
      const input = { CompanyIds: companyIds, DebtDate: debtDate };
      axios
        .post(
          SERVICEURL + "api/Finance/ListCurrentPeriodReportbyMonth",
          input,
          HEADER
        )
        .then((response) => {
          dispatch(getCurrentPeriodReportByMonthSuccess(response));
        })
        .catch((error: AxiosError) => {
          dispatch(getCurrentPeriodReportByMonthError("Save error."));
          if (error.response!.status === 401) {
            localStorage.removeItem("token");
          }
        });
    }
  };
}
export function getCurrentPeriodReportbyFirm(state: {
  companyIds: number[];
  state: { debtDatebyMonth: Date | null; companyId: number; firm: String };
}) {
  const debtDate = state.state.debtDatebyMonth;
  const companyId = state.state.companyId;
  const firm = state.state.firm;
  const companyIds = state.companyIds;

  return (dispatch: (arg0: memberActions) => void) => {
    if (debtDate != null || companyId != null) {
      const input = {
        CompanyIds: companyIds,
        DebtDate: debtDate,
        FirmName: firm,
      };
      axios
        .post(
          SERVICEURL + "api/Finance/ListCurrentPeriodReportbyFirm",
          input,
          HEADER
        )
        .then((response) => {
          dispatch(getCurrentPeriodReportByFirmSuccess(response));
        })
        .catch((error: AxiosError) => {
          dispatch(getCurrentPeriodReportByFirmError("Save error."));
          if (error.response!.status === 401) {
            localStorage.removeItem("token");
          }
        });
    }
  };
}
export function getEmployeesByInput(state: {
  state: { customerId: number; companyId: number };
}) {
  const companyId = state.state.companyId;
  const customerId = state.state.customerId;
  return (dispatch: (arg0: memberActions) => void) => {
    const input = {
      teamID: null,
      customerID: customerId,
      companyID: companyId,
    };
    axios
      .post(SERVICEURL + "api/Data/GetEmployeebyInput", input, HEADER)
      .then((response) => {
        dispatch(getEmployeesByInputSuccess(response));
      })
      .catch((error) => {
        dispatch(getEmployeesByInputError("Save error."));
      });
  };
}
export function getTechnicGroupMainList() {
  return (dispatch: (arg0: memberActions) => void) => {
    axios
      .get(SERVICEURL + "api/Data/GetTechnicalMainGroupList", HEADER)
      .then((response) => {
        dispatch(getTechnicGroupMainListSuccess(response));
      })
      .catch((error) => {
        dispatch(getTechnicGroupMainListError("Save error."));
      });
  };
}
export function getAllTechnicalGroupList() {
  return (dispatch: (arg0: memberActions) => void) => {
    axios
      .get(SERVICEURL + "api/Data/GetTechnicalGroup?", HEADER)
      .then((response) => {
        dispatch(getAllTechnicalGroupSuccess(response));
      })
      .catch((error) => {
        console.log(error);

        dispatch(getAllTechnicalGroupError("Save error."));
      });
  };
}
export function GetProfilabilityByCandidateList(state: {
  state: { year: number | null; companies: number; month: number | null };
}) {
  const Year = state.state.year;
  const Period = state.state.month;
  const CompanyId = state.state.companies;

  return (dispatch: (arg0: memberActions) => void) => {
    const input = { CompanyId: CompanyId, Month: Period, Year: Year };
    axios
      .post(
        SERVICEURL + "api/Finance/GetProfilabilityByCandidateInfoList",
        input,
        HEADER
      )
      .then((response) => {
        dispatch(getProfilabilityByCandidateListSuccess(response));
      })
      .catch((error: AxiosError) => {
        dispatch(getProfilabilityByCandidateListError("Save error."));
        if (error.response!.status === 401) {
          localStorage.removeItem("token");
        }
      });
  };
}
export function GetSelectionPlacementByCandidateList(state: {
  customerValue: number;
  state: {
    year: number | null;
    companies: number;
    memberId: number;
    month: number | null;
    işkur: boolean | null;
    isActive: boolean | null;
  };
}) {
  const Year = state.state.year;
  const Period = state.state.month;
  const CompanyId = state.state.companies;
  const IsActive = state.state.isActive;
  const IsIskur = state.state.işkur;
  const MemberId = state.state.memberId;
  const CustomerId = state.customerValue;

  return (dispatch: (arg0: memberActions) => void) => {
    const input = {
      CompanyId: CompanyId,
      MemberId: MemberId,
      Month: Period,
      Year: Year,
      IsActive: IsActive,
      IsIskur: IsIskur,
      CustomerId: CustomerId,
    };
    axios
      .post(
        SERVICEURL + "api/Finance/GetSelectionPositionProgress",
        input,
        HEADER
      )
      .then((response) => {
        dispatch(getSelectonPlacementByCandidateListSuccess(response));
      })
      .catch((error: AxiosError) => {
        dispatch(getSelectionPlacementByCandidateListError("Save error."));
        if (error.response!.status === 401) {
          localStorage.removeItem("token");
        }
      });
  };
}
export function getCandidateList() {
  return (dispatch: (arg0: memberActions) => void) => {
    axios
      .get(SERVICEURL + "api/Data/GetCandidateList?", HEADER)
      .then((response) => {
        dispatch(getCandidateListSuccess(response));
      })
      .catch((error) => {
        console.log(error);

        dispatch(getCandidateListError("Save error."));
      });
  };
}
export function getRecruiterList() {
  return (dispatch: (arg0: memberActions) => void) => {
    axios
      .get(SERVICEURL + "api/Data/GetIKPersonnel?", HEADER)
      .then((response) => {
        dispatch(getRecruiterListSuccess(response));
      })
      .catch((error) => {
        console.log(error);

        dispatch(getRecruiterListError("Save error."));
      });
  };
}

export function getOutsourcePersonnelList() {
  return (dispatch: (arg0: memberActions) => void) => {
    axios
      .get(SERVICEURL + "api/Data/GetOutsourcePersonnelList", HEADER)
      .then((response) => {
        dispatch(getOutsourcePersonnelListSuccess(response));
      })
      .catch((error) => {
        dispatch(getOutsourcePersonnelListError("Save error."));
      });
  };
}

export function getCompaniesByMemberIdList() {
  return (dispatch: (arg0: memberActions) => void) => {
    axios
      .get(SERVICEURL + "api/Data/GetCompaniesbyMemberId", HEADER)
      .then((response) => {
        dispatch(getCompaniesByMemberIdListSuccess(response));
      })
      .catch((error) => {
        dispatch(getCompaniesByMemberIdListError("Save error."));
      });
  };
}

export function getCustomerByProfitabilityList(state: {
  state: { companies: number };
}) {
  const CompanyId = state.state.companies;

  return (dispatch: (arg0: memberActions) => void) => {
    const input = { CompanyId: CompanyId };
    axios
      .post(SERVICEURL + "api/Data/GetCustomerbyProfitability", input, HEADER)
      .then((response) => {
        dispatch(getCustomerByProfitabilitySuccess(response));
      })
      .catch((error: AxiosError) => {
        dispatch(getCustomerByProfitabilityError("Save error."));
        if (error.response && error.response.status === 401) {
          localStorage.removeItem("token");
        }
      });
  };
}

export function getNettingList(state: {
  state: { companies: number; year: number | null; month: number | null };
}) {
  const CompanyId = state.state.companies;
  const Year = state.state.year;
  const Month = state.state.month;

  return (dispatch: (arg0: memberActions) => void) => {
    const input = { CompanyId: CompanyId, Year: Year, Month: Month };
    axios
      .post(SERVICEURL + "api/Finance/GetNettingList", input, HEADER)
      .then((response) => {
        dispatch(getNettingListSuccess(response));
      })
      .catch((error: AxiosError) => {
        dispatch(getNettingListError("Save error."));
        if (error.response!.status === 401) {
          localStorage.removeItem("token");
        }
      });
  };
}

export function getPositionList() {
  return (dispatch: (arg0: memberActions) => void) => {
    axios
      .get(SERVICEURL + "api/Data/GetPositionList", HEADER)
      .then((response) => {
        dispatch(getPositionListSuccess(response));
      })
      .catch((error) => {
        dispatch(getPositionListError("Save error."));
      });
  };
}

export function getSalarySummaryList(state: {
  state: {
    companyId: number;
    customerId: number;
    memberId: number;
  };
}) {
  const companyId = state.state.companyId;
  const customerId = state.state.customerId;
  const memberId = state.state.memberId;

  return (dispatch: (arg0: memberActions) => void) => {
    if (companyId != null || customerId != null || memberId != null) {
      const input = {
        CompanyId: companyId,
        CustomerId: customerId,
        MemberId: memberId,
      };
      axios
        .post(SERVICEURL + "api/Finance/SumSalaryList", input, HEADER)
        .then((response) => {
          dispatch(getSalarySummarySuccess(response));
        })
        .catch((error) => {
          dispatch(getSalarySummaryError("Save error."));
        });
    }
  };
}

export function getBudgetbyMainCode(state: {
  state: { period: Date | null; companyId: number };
}) {
  const period = state.state.period;
  const companyId = state.state.companyId;

  return (dispatch: (arg0: memberActions) => void) => {
    if (period != null || companyId != null) {
      const input = { CompanyId: companyId, Period: period };
      axios
        .post(SERVICEURL + "api/Finance/ListBudgetbyMainCode", input, HEADER)
        .then((response) => {
          dispatch(getBudgetByMainCodeSuccess(response));
        })
        .catch((error: AxiosError) => {
          dispatch(getBudgetByMainCodeError("Save error."));
          if (error.response!.status === 401) {
            localStorage.removeItem("token");
          }
        });
    }
  };
}

export function getBudgetBySubCode(state: {
  state: { period: Date | null; companyId: number; mainGroupCode: string };
}) {
  const period = state.state.period;
  const companyId = state.state.companyId;
  const mainGroupCode = state.state.mainGroupCode;

  return (dispatch: (arg0: memberActions) => void) => {
    if (period != null || companyId != null) {
      const input = {
        CompanyId: companyId,
        Period: period,
        CostCenterMainCode: mainGroupCode,
      };
      axios
        .post(SERVICEURL + "api/Finance/ListBudgetbySubCode", input, HEADER)
        .then((response) => {
          dispatch(getBudgetBySubCodeSuccess(response));
        })
        .catch((error: AxiosError) => {
          dispatch(getBudgetBySubCodeError("Save error."));
          if (error.response!.status === 401) {
            localStorage.removeItem("token");
          }
        });
    }
  };
}

export function getBudgetByCostCenterCode(state: {
  state: { period: Date | null; companyId: number; subGroupCode: string };
}) {
  const period = state.state.period;
  const companyId = state.state.companyId;
  const subGroupCode = state.state.subGroupCode;

  return (dispatch: (arg0: memberActions) => void) => {
    if (period != null || companyId != null) {
      const input = {
        CompanyId: companyId,
        Period: period,
        CostCenterSubCode: subGroupCode,
      };
      axios
        .post(
          SERVICEURL + "api/Finance/ListBudgetbyCostCenterCode",
          input,
          HEADER
        )
        .then((response) => {
          dispatch(getBudgetByCostCenterCodeSuccess(response));
        })
        .catch((error: AxiosError) => {
          dispatch(getBudgetByCostCenterCodeError("Save error."));
          if (error.response!.status === 401) {
            localStorage.removeItem("token");
          }
        });
    }
  };
}

export function getBudgetGeneralReport(state: {
  state: { period: Date | null; companyId: number };
}) {
  const period = state.state.period;
  const companyId = state.state.companyId;

  return (dispatch: (arg0: memberActions) => void) => {
    if (period != null || companyId != null) {
      const input = { CompanyId: companyId, Period: period };
      axios
        .post(SERVICEURL + "api/Finance/ListBudgetGeneralReport", input, HEADER)
        .then((response) => {
          dispatch(getBudgetGeneralReportSuccess(response));
        })
        .catch((error: AxiosError) => {
          dispatch(getBudgetGeneralReportError("Save error."));
          if (error.response!.status === 401) {
            localStorage.removeItem("token");
          }
        });
    }
  };
}

export function getBudgetMainReport(state: {
  state: { period: Date | null; companyId: number };
}) {
  const period = state.state.period;
  const companyId = state.state.companyId;

  return (dispatch: (arg0: memberActions) => void) => {
    if (period != null || companyId != null) {
      const input = { CompanyId: companyId, Period: period };
      axios
        .post(SERVICEURL + "api/Finance/ListBudgetMainReport", input, HEADER)
        .then((response) => {
          dispatch(getBudgetMainReportSuccess(response));
        })
        .catch((error: AxiosError) => {
          dispatch(getBudgetMainReportError("Save error."));
          if (error.response!.status === 401) {
            localStorage.removeItem("token");
          }
        });
    }
  };
}

export function getBudgetSubReport(state: {
  state: { period: Date | null; companyId: number; mainGroupCode: string };
}) {
  const period = state.state.period;
  const companyId = state.state.companyId;
  const mainCode = state.state.mainGroupCode;

  return (dispatch: (arg0: memberActions) => void) => {
    if (period != null || companyId != null) {
      const input = {
        CompanyId: companyId,
        Period: period,
        CostCenterMainCode: mainCode,
      };
      axios
        .post(SERVICEURL + "api/Finance/ListBudgetSubReport", input, HEADER)
        .then((response) => {
          dispatch(getBudgetSubReportSuccess(response));
        })
        .catch((error: AxiosError) => {
          console.log("error: " + error);
          dispatch(getBudgetSubReportError("Save error."));
          if (error.response!.status === 401) {
            localStorage.removeItem("token");
          }
        });
    }
  };
}

export function getBudgetDetailReport(state: {
  state: { period: Date | null; companyId: number; subGroupCode: string };
}) {
  const period = state.state.period;
  const companyId = state.state.companyId;
  const subGroupCode = state.state.subGroupCode;

  return (dispatch: (arg0: memberActions) => void) => {
    if (period != null || companyId != null) {
      const input = {
        CompanyId: companyId,
        Period: period,
        CostCenterSubCode: subGroupCode,
      };
      axios
        .post(SERVICEURL + "api/Finance/ListBudgetDetailReport", input, HEADER)
        .then((response) => {
          dispatch(getBudgetDetailReportSuccess(response));
        })
        .catch((error: AxiosError) => {
          console.log("error: " + error);
          dispatch(getBudgetDetailReportError("Save error."));
          if (error.response!.status === 401) {
            localStorage.removeItem("token");
          }
        });
    }
  };
}




export function getBudgetForeCastbyMainCodeList(state: {
  companyIds: number[];
  OperationalStability: { isOperationalStability: boolean };
  state: { debtDate: Date | null; companyId: number; inflationRate: number };
}) {
  const debtDate = state.state.debtDate;
  const companyId = state.state.companyId;
  const companyIds = state.companyIds;
  const inflationRate = state.state.inflationRate;





  const isOperationalStability =
    state.OperationalStability.isOperationalStability;

  return (dispatch: (arg0: memberActions) => void) => {
    if (debtDate != null || companyId != null) {
      const input = {
        CompanyIds: companyIds,
        CompanyId: companyId,
        DebtDate: debtDate,
        IsOperationalStability: isOperationalStability,
        InflationRate: inflationRate
      };
      axios
        .post(
          SERVICEURL + "api/Finance/ListBudgetForeCastbyMainCode",
          input,
          HEADER
        )
        .then((response) => {
          dispatch(getBudgetForecastListByMainCodeListSuccess(response));
        })
        .catch((error: AxiosError) => {
          dispatch(getBudgetForecastListByMainCodeListError("Save error."));
          if (error.response!.status === 401) {
            localStorage.removeItem("token");
          }
        });
    }
  };
}

export function getBudgetForeCastbySubCodeList(state: {
  companyIds: number[];
  OperationalStability: { isOperationalStability: boolean };
  state: { debtDate: Date | null; companyId: number; inflationRate: number; mainGroupCode: string };
}) {
  const debtDate = state.state.debtDate;
  const companyId = state.state.companyId;
  const companyIds = state.companyIds;
  const inflationRate = state.state.inflationRate;
  const mainGroupCode = state.state.mainGroupCode;

  const isOperationalStability =
    state.OperationalStability.isOperationalStability;

  return (dispatch: (arg0: memberActions) => void) => {
    if (debtDate != null || companyId != null) {
      const input = {
        CompanyIds: companyIds,
        CompanyId: companyId,
        DebtDate: debtDate,
        IsOperationalStability: isOperationalStability,
        InflationRate: inflationRate,
        MainGroupCode: mainGroupCode
      };
      axios
        .post(
          SERVICEURL + "api/Finance/ListBudgetForeCastbySubCode",
          input,
          HEADER
        )
        .then((response) => {
          dispatch(getBudgetForecastListBySubCodeListSuccess(response));
        })
        .catch((error: AxiosError) => {
          dispatch(getBudgetForecastListBySubCodeListError("Save error."));
          if (error.response!.status === 401) {
            localStorage.removeItem("token");
          }
        });
    }
  };
}

export function getBudgetForeCastbyCostCenterCodeList(state: {
  companyIds: number[];
  OperationalStability: { isOperationalStability: boolean };
  state: { debtDate: Date | null; companyId: number; inflationRate: number; subGroupCode: string; };
}) {
  const debtDate = state.state.debtDate;
  const companyId = state.state.companyId;
  const companyIds = state.companyIds;
  const inflationRate = state.state.inflationRate;
  const subGroupCode = state.state.subGroupCode;

  const isOperationalStability =
    state.OperationalStability.isOperationalStability;

    console.log("subgroupcode",subGroupCode)

  return (dispatch: (arg0: memberActions) => void) => {
    if (debtDate != null || companyId != null) {
      const input = {
        CompanyIds: companyIds,
        CompanyId: companyId,
        DebtDate: debtDate,
        IsOperationalStability: isOperationalStability,
        InflationRate: inflationRate,
        CostCenterSubCode: subGroupCode

      };
      axios
        .post(
          SERVICEURL + "api/Finance/ListBudgetForeCastbyCostCenterCode",
          input,
          HEADER
        )
        .then((response) => {
          dispatch(getBudgetForecastListByCostCenterCodeListSuccess(response));
        })
        .catch((error: AxiosError) => {
          dispatch(getBudgetForecastListByCostCenterCodeListError("Save error."));
          if (error.response!.status === 401) {
            localStorage.removeItem("token");
          }
        });
    }
  };
}
