import {
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";
import {
  getExchangeBankRatesList,
} from "../../redux/actions/memberAction";
import React, { useEffect, useReducer, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Button, Col, Heading, Row, Card, Table } from "../../components";
import Content from "../../layouts/content";
import { Container } from "./style";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Helmet } from "react-helmet";
import { List } from "@material-ui/icons";
import { SERVICEURL } from "../../appconfig";
import axios, { AxiosError } from "axios";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import { DataGrid, GridRenderCellParams } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import data from "../../shared/data/home-view/acquisitionchart";

const useStylesHeader = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      marginLeft: "115px",
      height: "60px",
    },
  })
);

const useStylesButton = makeStyles((theme: Theme) =>
  createStyles({
    buttonList: {
      fontWeight: "bold",
      margin: "7px",
      position: "relative",
      float: "right",
    },
    buttonIcon: {
      marginRight: "5px",
    },
    inputControl: {
      position: "relative",
      width: "100%",
      color: "black",
      height: "3.4rem",
      borderRadius: "4px",
      borderColor: "#AEADAD",
      fontSize: "16px",
    },
    buttonIconDelete: {
      position: "absolute",
      top: "15px",
      color: "danger",
      height: "27px",
      width: "27px",
    },
    buttonIconUpdate: {
      position: "relative",
      textAlign: "center",
    },
    buttonControl: {
      position: "absolute",
      width: "20rem",
      color: "black",
      height: "100%",
      borderRadius: "4px",
      borderColor: "#AEADAD",
      fontSize: "16px",
    },
    buttonLink: {
      color: "#e9e9e9",
      fontSize: "13px",
    },
    buttonLinkTechnic: {
      fontSize: "13px",
    },
    formControl: {
      position: "absolute",
      width: "92%",
      color: "grey",
      "& label.Mui-focused": {
        color: "#3b8c8c",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "#3b8c8c",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "black",
        },
        "&:hover fieldset": {
          borderColor: "orange",
        },
        "&.Mui-focused fieldset": {
          borderColor: "#3b8c8c",
        },
      },
    },
    TextFieldControl: {
      overflowWrap: "break-word",
      backgroundColor: "rgb(255, 255, 255)",
      backgroundClip: "border-box",
      border: "1px solid transparent",
      borderRadius: "4px",
      boxShadow: "rgb(26 108 225 / 10%) 0px 0px 25px",
      padding: "15px 15px 15px 15px",
      height: "100%",
    },
    colorBackground: {
      backgroundColor: "rgb(247,250,255)",
    },
    error: {
      borderColor: 'red',
      borderWidth: '1px',
      borderStyle: 'solid',
    },
  })
);

const useStylesLink = makeStyles((theme: Theme) =>
  createStyles({
    rdcLink: {
      color: "black",
    },
  })
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    scroll: {
      overflowY: "scroll",
      height: "100vh",
    },
    table: {
      textAlign: "center",
    },
  })
);

const useStylesSelect = makeStyles((theme: Theme) =>
  createStyles({
    inputControl2: {
      position: "relative",
      width: "100%",
      color: "black",
      height: "3.2rem",
      borderRadius: "4px",
      borderColor: "#AEADAD",
      fontSize: "16px",
      top: "-13px",
    },
    labelControl: {
      position: "relative",
      width: "100%",
      color: "#959393",
      top: "-13px",
      fontSize: "12px",
    },
  })
);

const CentralBankRates: React.FC<{}> = ({ }) => {
  const classesHeader = useStylesHeader();
  const classesName = useStylesButton();
  const classesButton = useStylesButton();
  const classesLink = useStylesLink();
  const classesStyle = useStyles();
  const classesSelect = useStylesSelect();

  const dis = useDispatch();
  type serviceResponse = {
    id: number;
    name: string;
  };
  type State = {
    dateStart: Date | null;
    dateEnd: Date | null;
    loading: boolean;
  };

  const initialState: State = {
    dateStart: null,
    dateEnd: null,
    loading: false,
  };

  type Action =
    | { type: "setDateStart"; payload: Date }
    | { type: "setDateEnd"; payload: Date }
    | { type: "setLoading"; payload: boolean };

  const reducer = (state: State, action: Action): State => {
    switch (action.type) {
      case "setDateStart":
        return {
          ...state,
          dateStart: action.payload,
        };
      case "setDateEnd":
        return {
          ...state,
          dateEnd: action.payload,
        };
      case "setLoading":
        return {
          ...state,
          loading: action.payload,
        };
      default:
        return state;
    }
  };

  const mytoken = localStorage.getItem("token");
  const [state, dispatch] = useReducer(reducer, initialState);
  const [openError, setOpenError] = React.useState(false);

  const { message, exchangeBankRateList = [] } = useSelector(
    (state: RootStateOrAny) => state.exchangeBankRateList
  );
  const [openWarning, setOpenWarning] = React.useState(false);
  const [openDateWarning, setOpenDateWarning] = React.useState(false);

  const [startDateError, setStartDateError] = useState(false);
  const [endDateError, setEndDateError] = useState(false);

  function getExchangeBankRate() {
    dis(getExchangeBankRatesList({ state }));
  }

  const HEADER = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + mytoken,
    },
  };

  const handleStartDate = (event: any) => {
    if (event != null) {
      setStartDateError(false);
      dispatch({
        type: "setDateStart",
        payload: new Date(event),
      });
    }
    else {
      setStartDateError(true);
      dispatch({
        type: "setDateStart",
        payload: new Date(""),
      });
    }
  };

  const handleEndDate = (event: any) => {
    if (event != null) {
      setEndDateError(false);
      dispatch({
        type: "setDateEnd",
        payload: new Date(event),
      });
    }
    else {
      setEndDateError(true);
      dispatch({
        type: "setDateEnd",
        payload: new Date(""),
      });
    }
  };

  const handleClose = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenWarning(false);
    setOpenDateWarning(false);
  };

  var pDate = state.dateStart?.toLocaleDateString();
  var dd = state.dateStart?.getDate();
  var mm = Number(state.dateStart?.getMonth()) + 1;
  var yyyy = state.dateStart?.getFullYear();
  var datestart = mm + "/" + dd + "/" + yyyy + " " + "5:30:00";
  var sdate = new Date(datestart);

  var pDate = state.dateEnd?.toLocaleDateString();
  var dd = state.dateEnd?.getDate();
  var mm = Number(state.dateEnd?.getMonth()) + 1;
  var yyyy = state.dateEnd?.getFullYear();
  var dateend = mm + "/" + dd + "/" + yyyy + " " + "22:30:00";
  var edate = new Date(dateend);

  if (
    state.dateStart != null &&
    state.dateEnd != null &&
    state.dateStart.getFullYear() != 1970 &&
    state.dateEnd.getFullYear() != 1970
  ) {
    state.dateStart = sdate;
    state.dateEnd = edate;
  }
  const getExchangeBankRates = () => {
    let valid = true;
    if (state.dateStart == null) {
      setStartDateError(true);
      valid = false;
    }
    if (state.dateEnd == null) {
      setEndDateError(true);
      valid = false;
    }
    if (state.dateStart != null && state.dateEnd != null
      && state.dateStart.getFullYear() == 1970 &&
      state.dateEnd.getFullYear() == 1970) {
      valid = false;
    }

    if (valid) {
      if (state.dateStart != null && state.dateEnd != null
        && state.dateEnd > state.dateStart) {
        dispatch({
          type: "setLoading",
          payload: true,
        });
        getExchangeBankRate();
      } else {
        setOpenDateWarning(true);
      }
    } else {
      setOpenWarning(true);
    }
  };

  const Nav = React.lazy(() => import("../../layouts/nav/Nav"));

  useEffect(() => {
    dispatch({
      type: "setLoading",
      payload: false,
    });
  }, [message, exchangeBankRateList]);

  return (
    <>
      <Helmet>
        <title>RDC Board ~ Merkez Bankası Kurları</title>
      </Helmet>
      <div className={classesStyle.scroll}>
        <Nav />
        <div className={classesName.colorBackground}>
          <Content>
            <Heading as="h5" mb="5px" className={classesHeader.header}>
              Merkez Bankası Kurları
            </Heading>
            <Container className={classesName.TextFieldControl}>
              <Row>
                <Col lg={3} md sm xs mb={25}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Başlangıç Tarihi"
                      value={state.dateStart}
                      onChange={handleStartDate}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          error={startDateError}
                          helperText={startDateError ? 'Bu alan boş bırakılamaz' : ''}
                        />
                      )}
                      inputFormat="dd/MM/yyyy"
                    />
                  </LocalizationProvider>
                </Col>
                <Col lg={3} md sm xs mb={25}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Bitiş Tarihi"
                      value={state.dateEnd}
                      onChange={handleEndDate}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          error={endDateError}
                          helperText={endDateError ? 'Bu alan boş bırakılamaz' : ''}
                        />
                      )}
                      inputFormat="dd/MM/yyyy"
                    />
                  </LocalizationProvider>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <Button
                    iconButton
                    size="lg"
                    color="light"
                    className={classesButton.buttonList}
                    onClick={getExchangeBankRates}
                  >
                    <List
                      width={18}
                      height={18}
                      className={classesButton.buttonIcon}
                    />
                    LİSTELE
                  </Button>
                  <Snackbar
                    open={openWarning}
                    autoHideDuration={1000}
                    onClose={handleClose}
                  >
                    <Alert
                      onClose={() => handleClose}
                      severity="warning"
                      sx={{ width: "100%" }}
                    >
                      Tarih Seçeneğini boş geçilemez.
                    </Alert>
                  </Snackbar>
                  <Snackbar
                    open={openDateWarning}
                    autoHideDuration={3000}
                    onClose={handleClose}
                  >
                    <Alert
                      onClose={() => handleClose}
                      severity="warning"
                      sx={{ width: "100%" }}
                    >
                      Başlangıç tarihi bitiş tarihinden daha sonraki bir tarihi belirtemez.
                    </Alert>
                  </Snackbar>
                  <Backdrop
                    sx={{
                      color: "#fff",
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={state.loading}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                </Col>
              </Row>
              <br />
              <br />
              {Object.values(exchangeBankRateList).length > 0 && (
                <Card width={1000}>
                  <Table bordered marginBottom={"0px"}>
                    <thead>
                      <tr>
                        <th style={{ width: "66px", paddingLeft: "20px", fontSize: 12 }}>
                          {/* Kolon 1 */}
                        </th>
                        <th style={{ width: "100px", paddingLeft: "20px", fontSize: 15 }}>
                          DOLAR
                        </th>
                        <th style={{ width: "100px", paddingLeft: "20px", fontSize: 15 }}>
                          EURO
                        </th>
                        <th style={{ width: "100px", paddingLeft: "20px", fontSize: 15 }}>
                          STERLİN
                        </th>
                      </tr>
                    </thead>
                  </Table>

                  <DataGrid
                    sx={{ fontSize: "13px", overflowX: "scroll" }}
                    autoHeight={true}
                    rowHeight={50}
                    rows={Object.values(exchangeBankRateList.data).map(
                      (item: any, index: any) => ({
                        id: index,
                        dateTime: item.DateTime ?? "",
                        exchangeRateforDolar: item.ExchangeRateforDolar,
                        exchangeRateforEuro: item.ExchangeRateforEuro,
                        exchangeRateforSterlin: item.ExchangeRateforSterlin,
                        exchangeRateforDolarBuy: item.ExchangeRateforDolarBuy,
                        exchangeRateforEuroBuy: item.ExchangeRateforEuroBuy,
                        exchangeRateforSterlinBuy: item.ExchangeRateforSterlinBuy,
                      })
                    )}
                    columns={[
                      {
                        field: "id",
                        headerName: "ID",
                        hide: true,
                      },
                      {
                        field: "dateTime",
                        headerName: "Tarih",
                        flex: 3,
                      },
                      {
                        field: "exchangeRateforDolarBuy",
                        headerName: "ALIŞ",
                        flex: 2,
                      },
                      {
                        field: "exchangeRateforDolar",
                        headerName: "SATIŞ",
                        flex: 2,
                      },
                      {
                        field: "exchangeRateforEuroBuy",
                        headerName: "ALIŞ",
                        flex: 2,
                      },
                      {
                        field: "exchangeRateforEuro",
                        headerName: "SATIŞ",
                        flex: 2,
                      },
                      {
                        field: "exchangeRateforSterlinBuy",
                        headerName: "ALIŞ",
                        flex: 2,
                      },
                      {
                        field: "exchangeRateforSterlin",
                        headerName: "SATIŞ",
                        flex: 2,
                      },
                    ]}
                  />
                </Card>
              )}
              <br />
            </Container>
          </Content>
        </div>
      </div>
    </>
  );
};

export default CentralBankRates;
